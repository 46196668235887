import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';
import { Color, CustomSpacing, Teaser } from '@genially/public-web-components';

import type { FC } from 'react';
import type { MediaIframeDTO } from 'core/domain/Media/MediaIframeDTO';
import type { MediaImageDTO } from 'core/domain/Media/MediaImageDTO';
import type { MediaVideoDTO } from 'core/domain/Media/MediaVideoDTO';
import isNil from 'lodash/isNil';
import { parseMedia } from 'core/infrastructure/parseMedia';

interface ParagraphTeaserButtonProps {
  url: { path: string };
  title: string;
}

interface ParagraphTeaserButtonStyleProps {
  size: string;
  variant: string;
}

const VARIANT_1COL = '1col';
const VARIANT_2COL = '2col';

interface ParagraphTeaserProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  heading?: string;
  headingType?: string;
  headingColor?: Color;
  media?: MediaIframeDTO | MediaVideoDTO | MediaImageDTO;
  title?: string;
  titleType?: string;
  titleColor?: Color;
  subtitle?: string;
  subtitleType?: string;
  subtitleColor?: Color;
  mediaBorRadius?: number;
  variant?: typeof VARIANT_1COL | typeof VARIANT_2COL;
  flip: boolean;
  flipMobile: boolean;
  paragraph?: { processed: string };
  paragraphType?: string;
  paragraphColor?: Color;
  buttonPrimary?: ParagraphTeaserButtonProps;
  buttonPrimaryStyle?: ParagraphTeaserButtonStyleProps;
  buttonSecondary?: ParagraphTeaserButtonProps;
  buttonSecondaryStyle?: ParagraphTeaserButtonStyleProps;
  animation?: string;
  first: boolean;
  teaserContent?: any;
}

export const ParagraphTeaser: FC<ParagraphTeaserProps> = ({
  behaviorSettings,
  heading,
  headingType,
  headingColor,
  media,
  title,
  titleType,
  titleColor,
  subtitle,
  subtitleType,
  subtitleColor,
  mediaBorRadius,
  variant,
  flip,
  flipMobile,
  paragraph,
  paragraphType,
  paragraphColor,
  buttonPrimary,
  buttonPrimaryStyle,
  buttonSecondary,
  buttonSecondaryStyle,
  animation = 'fadeInUp',
  first,
  teaserContent,
}) => {
  return (
    <Teaser
      animation={animation}
      first={first}
      columns={variant === VARIANT_1COL ? 1 : 2}
      flip={flip && flip}
      flipMobile={flipMobile && flipMobile}
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      media={parseMedia(media)}
      content={teaserContent}
      mediaBorRadius={mediaBorRadius}
      heading={
        heading
          ? {
              text: heading,
              type: headingType,
              color: headingColor,
            }
          : undefined
      }
      title={
        title
          ? {
              text: title,
              type: titleType,
              color: titleColor,
            }
          : undefined
      }
      subtitle={
        subtitle
          ? {
              text: subtitle,
              type: subtitleType,
              color: subtitleColor,
            }
          : undefined
      }
      paragraph={
        paragraph
          ? { text: paragraph.processed, color: paragraphColor, type: paragraphType }
          : undefined
      }
      buttons={getButtons({
        buttonPrimary,
        buttonPrimaryStyle,
        buttonSecondary,
        buttonSecondaryStyle,
      })}
    />
  );
};

export const getButtons = ({
  buttonPrimary,
  buttonPrimaryStyle,
  buttonSecondary,
  buttonSecondaryStyle,
}: any) => {
  const buttons = [];
  if (!isNil(buttonPrimary)) {
    buttons.push({
      variant: buttonPrimaryStyle.variant,
      size: buttonPrimaryStyle.size,
      children: buttonPrimary.title,
      href: buttonPrimary.url.path,
    });
  }

  if (!isNil(buttonSecondary)) {
    buttons.push({
      variant: buttonSecondaryStyle.variant,
      size: buttonSecondaryStyle.size,
      children: buttonSecondary.title,
      href: buttonSecondary.url.path,
    });
  }

  return buttons;
};
