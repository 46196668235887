import { Cards, Color, CustomSpacing, TextAttributes } from '@genially/public-web-components';

import type { BackgroundDTO } from 'core/infrastructure/parseBackground';
import type { FC } from 'react';
import isNil from 'lodash/isNil';
import { parseBackground } from 'core/infrastructure/parseBackground';
import { parseMedia } from 'core/infrastructure/parseMedia';

interface ParagraphCardWithLinksButtonProps {
  url: { path: string };
  title: string;
}

interface ParagraphCardWithLinksButtonStyleProps {
  size: string;
  variant: string;
}

interface ParagraphCardsWithLinkProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  title?: string;
  titleType?: string;
  titleColor?: Color;
  heading?: string;
  headingType?: string;
  headingColor?: Color;
  subtitle?: string;
  subtitleType?: string;
  subtitleColor?: Color;
  paragraph?: { value: string };
  paragraphColor?: Color;
  firstSlider?: ParagraphCardsWithLinkItemDTO[];
  secondSlider?: ParagraphCardsWithLinkItemDTO[];
  buttonPrimary?: ParagraphCardWithLinksButtonProps;
  buttonPrimaryStyle?: ParagraphCardWithLinksButtonStyleProps;
  buttonSecondary?: ParagraphCardWithLinksButtonProps;
  buttonSecondaryStyle?: ParagraphCardWithLinksButtonStyleProps;
  categoryWrapper?: TemplateCategory;
  numberOfTemplates?: any;
  autoplay?: boolean;
  controls?: boolean;
  titlePosition?: string;
  titleURL?: {
    url?: { path: string };
  };
  langcode?: any;
  templates?: any[];
}

export interface ParagraphCardsWithLinkItemDTO {
  item: any;
}

export interface TemplateCategory {
  category: {
    tid: string;
    mainRelatedContents: {
      entities: any[];
    };
  };
}

export const ParagraphCardsWithLink: FC<ParagraphCardsWithLinkProps> = ({
  behaviorSettings,
  title,
  titleType,
  titleColor,
  subtitle,
  subtitleType,
  subtitleColor,
  heading,
  headingType,
  headingColor,
  paragraph,
  paragraphColor,
  firstSlider,
  secondSlider,
  buttonPrimary,
  buttonPrimaryStyle,
  buttonSecondary,
  buttonSecondaryStyle,
  categoryWrapper,
  numberOfTemplates = 12,
  autoplay = 1,
  controls,
  titleURL,
  titlePosition,
  langcode,
  templates,
}) => {
  const maxTemplates = templates ? templates.length : numberOfTemplates;

  const renderedTemplatesFirstRow = templates?.slice(0, maxTemplates / 2);
  const renderedTemplatesSecondRow = templates?.slice(maxTemplates / 2, maxTemplates);

  return (
    <Cards
      variant="carousel"
      autoplay={autoplay ? 1 : 0}
      hideControls={controls ? false : true}
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      heading={
        heading
          ? {
              text: heading,
              type: headingType,
              color: headingColor,
            }
          : undefined
      }
      title={
        title
          ? {
              text: title,
              type: titleType,
              color: titleColor,
            }
          : undefined
      }
      subtitle={
        subtitle
          ? {
              text: subtitle,
              type: subtitleType,
              color: subtitleColor,
            }
          : undefined
      }
      paragraph={
        paragraph
          ? {
              text: paragraph.value,
              color: paragraphColor,
            }
          : undefined
      }
      firstSlider={
        categoryWrapper
          ? parseCardsCarouselTemplateItems(renderedTemplatesFirstRow, langcode)
          : parseCardsCarouselItems(firstSlider, langcode)
      }
      secondSlider={
        categoryWrapper
          ? parseCardsCarouselTemplateItems(renderedTemplatesSecondRow, langcode)
          : parseCardsCarouselItems(secondSlider, langcode)
      }
      templateCard={categoryWrapper ? true : false}
      titleURL={titleURL}
      titlePosition={titlePosition}
      buttons={getButtons({
        buttonPrimary,
        buttonPrimaryStyle,
        buttonSecondary,
        buttonSecondaryStyle,
      })}
    />
  );
};

interface CarouselSlide {
  media?: any;
  subtitle?: TextAttributes;
  title?: TextAttributes;
  url?: string;
}

export const parseCardsCarouselItems = (
  items?: ParagraphCardsWithLinkItemDTO[],
  langcode?: string
): CarouselSlide[] | undefined => {
  if (!items || items.length == 0) return undefined;

  return items.map((item: ParagraphCardsWithLinkItemDTO) =>
    item.item.template
      ? {
          media: item?.item?.template?.entity?.teaserImage
            ? parseMedia(
                item.item.template.entity.teaserImage,
                true,
                langcode,
                item.item.template.entity.displayTitle ? item.item.template.entity.displayTitle : ''
              )
            : undefined,
          price: item?.item?.template?.entity?.price ? 'premium' : 'free',
          title: item?.item?.template?.entity?.displayTitle
            ? { text: item.item.template.entity.displayTitle }
            : undefined,
          url: item?.item?.template?.entity?.url ? item.item.template.entity.url.path : undefined,
          roundedBorder: true,
        }
      : {
          media: item.item.media ? parseMedia(item.item.media) : undefined,
          title: item.item.title
            ? { text: item.item.title, color: item.item.titleColor, type: item.item.titleType }
            : undefined,
          url: item.item.url ? item.item.url.url.path : undefined,
          roundedBorder: false,
        }
  );
};

export const parseCardsCarouselTemplateItems = (
  items?: any[],
  langcode?: any
): CarouselSlide[] | undefined => {
  if (!items || items.length == 0) return undefined;
  const parsedItems: any[] = [];

  function teaserImage(item: any) {
    return {
      entity: {
        type: 'MediaImage',
        mediaImage: {
          alt: item.title ? item.title : '',
          title: item.title ? item.title : '',
          width: 1200,
          height: 675,
          src: item.thumbnail,
        },
      },
    };
  }

  items.map((item: any) => {
    if (item.contents) {
      const content = item.contents.filter((prefiltered: any) => {
        return prefiltered.lang === 'PT'
          ? 'pt-br' === langcode
          : prefiltered.lang.toLowerCase() === langcode && prefiltered.hide === false;
      });

      if (content[0]) {
        const parsed = {
          media: item
            ? parseMedia(
                teaserImage(content[0]),
                true,
                langcode,
                content[0].title ? content[0].title : ''
              )
            : undefined,
          price: item.price ? 'premium' : 'free',
          title: content[0].title ? { text: content[0].title } : undefined,
          url:
            content[0].lang !== 'EN'
              ? content[0].lang === 'PT'
                ? `/pt-br${content[0].urlPath}/`
                : '/' + content[0].lang.toLocaleLowerCase() + content[0].urlPath + '/'
              : content[0].urlPath + '/',
        };
        parsedItems.push(parsed);
      }
    }
  });
  return parsedItems;
};

export const getButtons = ({
  buttonPrimary,
  buttonPrimaryStyle,
  buttonSecondary,
  buttonSecondaryStyle,
}: any) => {
  const buttons = [];
  if (!isNil(buttonPrimary)) {
    buttons.push({
      variant: buttonPrimaryStyle.variant,
      size: buttonPrimaryStyle.size,
      children: buttonPrimary.title,
      href: buttonPrimary.url.path,
    });
  }

  if (!isNil(buttonSecondary)) {
    buttons.push({
      variant: buttonSecondaryStyle.variant,
      size: buttonSecondaryStyle.size,
      children: buttonSecondary.title,
      href: buttonSecondary.url.path,
    });
  }

  return buttons;
};
