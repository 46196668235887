import { Color, CustomSpacing, IconContainer } from '@genially/public-web-components';

import type { FC } from 'react';
import { parseBackground } from 'core/infrastructure/parseBackground';

export interface BackgroundDTO {
  backgroundType: 'image' | 'video';
  baseColor: {
    color: Color;
  };
  image?: {
    desktop?: ImageSource;
    laptop?: ImageSource;
    tablet?: ImageSource;
    mobile?: ImageSource;
  };
}

interface ImageSource {
  src: string;
}

interface ParagraphIconProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
    layout_paragraphs: { parent_uuid: string; region: string };
  };
  iconSVG: any;
  color?: Color;
  size?: string;
}

export const ParagraphIcon: FC<ParagraphIconProps> = ({
  behaviorSettings,
  iconSVG,
  size,
  color,
}) => {
  return (
    <IconContainer
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      icon={{
        icon: { icon: iconSVG ? iconSVG : '' },
        iconSize: size,
        iconColor: color,
      }}
    />
  );
};
