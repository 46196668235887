import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';
import { Banner, CustomSpacing, theme } from '@genially/public-web-components';

import type { ButtonDTO } from 'core/domain/Button/ButtonDTO';
import type { ButtonStyleDTO } from 'core/domain/Button/ButtonStyleDTO';
import type { FC } from 'react';

type Color = keyof typeof theme.colors;

interface ParagraphBannerProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  icon: { icon: string };
  iconSVG: any;
  iconColor: Color;
  iconSize?: string;
  title?: string;
  titleType?: string;
  titleColor?: Color;
  subtitle?: string;
  subtitleType?: string;
  subtitleColor?: Color;
  button?: ButtonDTO;
  buttonStyle: ButtonStyleDTO;
  animation?: string;
}

export const ParagraphBanner: FC<ParagraphBannerProps> = ({
  behaviorSettings,
  icon,
  iconSVG,
  iconSize,
  iconColor,
  title,
  titleType,
  titleColor,
  subtitle,
  subtitleType,
  subtitleColor,
  button,
  buttonStyle,
  animation = 'fadeInUp',
}) => {
  return (
    <Banner
      background={parseBackground(behaviorSettings.rowStyle.background)}
      animation={animation}
      icon={{
        icon: { icon: iconSVG ? iconSVG : icon },
        iconSize: iconSize,
        iconColor: iconColor,
      }}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      title={
        title
          ? {
              text: title,
              type: titleType,
              color: titleColor,
            }
          : undefined
      }
      subtitle={
        subtitle
          ? {
              text: subtitle,
              type: subtitleType,
              color: subtitleColor,
            }
          : undefined
      }
      button={
        button
          ? {
              variant: buttonStyle.variant,
              size: buttonStyle.size,
              children: button.title,
              href: button.url.path,
            }
          : undefined
      }
    />
  );
};
