import { ButtonContainer, Color, CustomSpacing } from '@genially/public-web-components';

import type { FC } from 'react';
import { parseBackground } from 'core/infrastructure/parseBackground';

export interface BackgroundDTO {
  backgroundType: 'image' | 'video';
  baseColor: {
    color: Color;
  };
  image?: {
    desktop?: ImageSource;
    laptop?: ImageSource;
    tablet?: ImageSource;
    mobile?: ImageSource;
  };
}

interface ImageSource {
  src: string;
}

interface ParagraphButtonProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
    layout_paragraphs: { parent_uuid: string; region: string };
  };
  button: { url: { path: string }; title: string };
  buttonStyle: { size: string; variant: string };
  buttonSize: string;
}

export const ParagraphButton: FC<ParagraphButtonProps> = ({
  button,
  buttonStyle,
  behaviorSettings,
  buttonSize,
}) => {
  return (
    <ButtonContainer
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      url={button.url.path}
      title={button.title}
      size={buttonSize}
      variant={buttonStyle.variant}
    />
  );
};
