import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';
import { Color, CustomSpacing, Play } from '@genially/public-web-components';

import type { FC } from 'react';

interface ParagraphPlayProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };

  paragraph?: { value: string };
  paragraphColor?: Color;
}
export const ParagraphPlay: FC<ParagraphPlayProps> = ({
  paragraph,
  paragraphColor,
  behaviorSettings,
}) => {
  return (
    <Play
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      paragraph={paragraph ? { text: paragraph.value, color: paragraphColor } : undefined}
    />
  );
};
