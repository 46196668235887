import { Cards, Color, CustomSpacing, TextAttributes } from '@genially/public-web-components';

import type { BackgroundDTO } from 'core/infrastructure/parseBackground';
import type { FC } from 'react';
import { parseBackground } from 'core/infrastructure/parseBackground';
import { parseMedia } from 'core/infrastructure/parseMedia';

interface ParagraphCardsGridProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  title?: string;
  titleType?: string;
  titleColor?: Color;
  heading?: string;
  headingType?: string;
  headingColor?: Color;
  paragraph?: { processed: string };
  paragraphColor?: Color;
  cards?: ParagraphCardsGridItemDTO[];
  button?: { url: { path: string }; title: string };
  buttonStyle?: { size: string; variant: string };
  elementsPerRow?: number;
  categoryWrapper?: TemplateCategory;
  numberOfTemplates?: any;
  masonry?: boolean;
  langcode?: any;
  templates?: any[];
}

export interface ParagraphCardsGridItemDTO {
  entity: any;
}
export interface TemplateCategory {
  category: {
    tid: string;
    mainRelatedContents: {
      entities: any[];
    };
  };
}

export const ParagraphCardsGrid: FC<ParagraphCardsGridProps> = ({
  behaviorSettings,
  title,
  titleType,
  titleColor,
  heading,
  headingType,
  headingColor,
  paragraph,
  paragraphColor,
  cards,
  button,
  buttonStyle,
  elementsPerRow = 2,
  categoryWrapper,
  numberOfTemplates = 12,
  masonry,
  langcode,
  templates,
}) => {
  const categoryTemplates = templates?.slice(0, numberOfTemplates);

  return (
    <Cards
      variant="grid"
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      elementsPerRow={elementsPerRow}
      masonry={masonry}
      heading={
        heading
          ? {
              text: heading,
              type: headingType,
              color: headingColor,
            }
          : undefined
      }
      title={
        title
          ? {
              text: title,
              type: titleType,
              color: titleColor,
            }
          : undefined
      }
      paragraph={
        paragraph
          ? {
              text: paragraph.processed,
              color: paragraphColor,
            }
          : undefined
      }
      cards={
        categoryWrapper
          ? parseCardsCarouselTemplateItems(categoryTemplates, langcode)
          : parseCardsGridItems(cards, langcode)
      }
      templateCard={categoryWrapper ? true : false}
      button={
        button && buttonStyle
          ? {
              variant: buttonStyle.variant,
              size: buttonStyle.size,
              children: button.title,
              href: button.url.path,
            }
          : undefined
      }
    />
  );
};

interface CardItem {
  media?: any;
  subtitle?: TextAttributes;
  title?: TextAttributes;
  url?: string;
}

export const parseCardsGridItems = (
  items?: ParagraphCardsGridItemDTO[],
  langcode?: string
): CardItem[] | undefined => {
  if (!items || items.length == 0) return undefined;

  return items.map((item: ParagraphCardsGridItemDTO) =>
    item.entity.create
      ? item.entity.create.entity.lang?.value !== langcode
        ? { disabled: true }
        : {
            media: item.entity.create.entity.media
              ? parseMedia(
                  item.entity.create.entity.media,
                  true,
                  langcode,
                  item.entity.create.entity.displayTitle
                    ? item.entity.create.entity.displayTitle
                    : ''
                )
              : undefined,
            title: item.entity.create.entity.displayTitle
              ? { text: item.entity.create.entity.displayTitle }
              : undefined,
            url: item.entity.create.entity.url ? item.entity.create.entity.url.path : undefined,
          }
      : item.entity.template
      ? {
          media: item.entity.template.entity.teaserImage
            ? parseMedia(
                item.entity.template.entity.teaserImage,
                true,
                langcode,
                item.entity.template.entity.displayTitle
                  ? item.entity.template.entity.displayTitle
                  : ''
              )
            : undefined,
          premium: item.entity.template.entity.price,
          title: item.entity.template.entity.displayTitle
            ? { text: item.entity.template.entity.displayTitle }
            : undefined,
          url: item.entity.template.entity.url ? item.entity.template.entity.url.path : undefined,
          roundedBorder: true,
        }
      : {
          upperMedia: item.entity.upperMedia ? parseMedia(item.entity.upperMedia) : undefined,
          media: item.entity.media ? parseMedia(item.entity.media) : undefined,
          title: item.entity.title
            ? {
                text: item.entity.title,
                color: item.entity.titleColor,
                type: item.entity.titleType,
              }
            : undefined,
          heading: item.entity.heading
            ? {
                text: item.entity.heading,
                type: item.entity.headingType,
                color: item.entity.headingColor,
              }
            : undefined,
          paragraph: item.entity.paragraph
            ? {
                text: item.entity.paragraph.processed,
                color: item.entity.paragraphColor,
              }
            : undefined,
          url: item.entity.url ? item.entity.url.url.path : undefined,
          roundedBorder: false,
        }
  );
};

export const parseCardsCarouselTemplateItems = (
  items?: any[],
  langcode?: string
): any[] | undefined => {
  if (!items || items.length == 0) return undefined;

  const parsedItems: any[] = [];

  function teaserImage(item: any) {
    return {
      entity: {
        type: 'MediaImage',
        mediaImage: {
          alt: item.title ? item.title : '',
          title: item.title ? item.title : '',
          width: 1200,
          height: 675,
          src: item.thumbnail,
        },
      },
    };
  }

  items.map((item: any) => {
    if (item.contents) {
      const content = item.contents.filter((prefiltered: any) => {
        return prefiltered.lang === 'PT'
          ? 'pt-br' === langcode
          : prefiltered.lang.toLowerCase() === langcode && prefiltered.hide === false;
      });

      if (content[0]) {
        const parsed = {
          media: item
            ? parseMedia(
                teaserImage(content[0]),
                true,
                langcode,
                content[0].title ? content[0].title : ''
              )
            : undefined,
          price: item.price ? 'premium' : 'free',
          title: content[0].title ? { text: content[0].title } : undefined,
          url:
            content[0].lang !== 'EN'
              ? content[0].lang === 'PT'
                ? `/pt-br${content[0].urlPath}/`
                : '/' + content[0].lang.toLocaleLowerCase() + content[0].urlPath + '/'
              : content[0].urlPath + '/',
        };
        parsedItems.push(parsed);
      }
    }
  });
  return parsedItems;
};
