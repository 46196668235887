import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';
import { Brands, Color, CustomSpacing } from '@genially/public-web-components';

import type { FC } from 'react';

interface ParagraphBrandsProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  heading?: string;
  headingColor?: string;
  headingType?: string;
  title?: string;
  titleType?: string;
  titleColor?: Color;
  paragraph?: { processed: string };
  paragraphColor?: Color;
  theme: keyof ThemeVariants;
  brands?: BrandDTO[];
  button?: { url: { path: string }; title: string };
  buttonStyle?: { size: string; variant: string };
  animation?: string;
  first?: boolean;
}

export interface BrandDTO {
  entity: {
    light?: {
      entity: any;
    };
    dark?: {
      entity: any;
    };
  };
  first?: boolean;
}

export interface Brand {
  src?: any;
  width?: string;
  alt?: string;
  type?: string;
  first?: boolean;
}

export const ParagraphBrands: FC<ParagraphBrandsProps> = ({
  behaviorSettings,
  heading,
  headingType,
  headingColor,
  title,
  titleType,
  titleColor,
  paragraph,
  paragraphColor,
  theme,
  brands,
  button,
  buttonStyle,
  animation,
  first,
}) => {
  return (
    <Brands
      first={first}
      animation={animation}
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      category={
        heading
          ? { text: heading, color: headingColor ? headingColor : 'rAkihabara', type: headingType }
          : undefined
      }
      title={
        title
          ? { text: title, color: titleColor ? titleColor : 'rBlack', type: titleType }
          : undefined
      }
      paragraph={
        paragraph
          ? { text: paragraph.processed, color: paragraphColor ? paragraphColor : 'rBlack' }
          : undefined
      }
      brands={mapperBrandsItems(brands, theme)}
      button={
        button && buttonStyle
          ? {
              variant: buttonStyle.variant,
              size: buttonStyle.size,
              children: button.title,
              href: button.url.path,
            }
          : undefined
      }
    />
  );
};

export type ThemeKeys = 'light' | 'dark';
export type ThemeVariants = {
  [key in ThemeKeys]: string;
};

export const mapperBrandsItems = (brands: BrandDTO[] = [], theme: keyof ThemeVariants): Brand[] => {
  if (!brands) return [];

  return brands
    .filter(brand => theme in brand.entity)
    .map((brand: BrandDTO) => {
      const itemImage = brand.entity[theme]?.entity;
      return {
        type: itemImage ? 'image' : undefined,
        src: {
          desktop: itemImage?.mediaImage ? itemImage.mediaImage.src : null,
          mobile: itemImage?.mediaImageMobile?.src
            ? itemImage?.mediaImageMobile?.src
            : itemImage?.mediaImage?.src,
          tablet: itemImage?.mediaImageTablet?.src
            ? itemImage?.mediaImageTablet?.src
            : itemImage?.mediaImage?.src,
          laptop: itemImage?.mediaImageLaptop?.src
            ? itemImage?.mediaImageLaptop?.src
            : itemImage?.mediaImage?.src,
        },
        width: itemImage?.mediaImage.width ? itemImage.mediaImage.width + 'px' : undefined,
        height: itemImage?.mediaImage.height ? itemImage.mediaImage.height + 'px' : undefined,
        alt: itemImage?.mediaImage.alt,
      };
    });
};
