import { Accordion, Color, CustomSpacing } from '@genially/public-web-components';
import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';

import type { AccordionItemValues } from '@genially/public-web-components';
import type { FC } from 'react';

interface ParagraphAccordeonProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  heading?: string;
  headingType?: string;
  headingColor?: Color;
  title?: string;
  titleType?: string;
  titleColor?: Color;
  paragraph?: { value: string };
  paragraphColor?: Color;
  items?: AccordeonItemDTO[];
  button?: { url: { path: string }; title: string };
  buttonStyle?: { size: string; variant: string };
  animation?: string;
}

export interface AccordeonItemDTO {
  item: {
    title: string;
    titleType: string;
    titleColor: Color;
    paragraph: {
      processed: string;
    };
    paragraphColor: Color;
  };
}

export const ParagraphAccordeon: FC<ParagraphAccordeonProps> = ({
  behaviorSettings,
  heading,
  headingType,
  headingColor,
  title,
  titleType,
  titleColor,
  paragraph,
  paragraphColor,
  items,
  button,
  buttonStyle,
  animation = 'fadeInUp',
}) => {
  return (
    <Accordion
      animation={animation}
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      subtitle={
        heading
          ? { text: heading, color: headingColor ?? 'rAkihabara', type: headingType }
          : undefined
      }
      title={title ? { text: title, color: titleColor ?? 'rBlack', type: titleType } : undefined}
      text={paragraph ? { text: paragraph.value, color: paragraphColor ?? 'rBlack' } : undefined}
      button={
        button && buttonStyle
          ? {
              variant: buttonStyle.variant,
              size: buttonStyle.size,
              title: button.title,
              href: button.url.path,
            }
          : undefined
      }
      accordionItems={parseAccordeonItems(items)}
    />
  );
};

export const parseAccordeonItems = (itemsDTO: AccordeonItemDTO[] = []): AccordionItemValues[] => {
  if (!itemsDTO) return [];

  return itemsDTO.map((item: AccordeonItemDTO, index: number) => {
    return {
      id: index + 1,
      title: item.item.title
        ? { text: item.item.title, color: item.item.titleColor, type: item.item.titleType }
        : undefined,
      paragraph: item.item.paragraph
        ? { text: item.item.paragraph.processed, color: item.item.paragraphColor }
        : undefined,
    };
  });
};
